import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClipboardModule } from 'ngx-clipboard';

import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { IconsComponent } from '../../pages/icons/icons.component';
import { MapsComponent } from '../../pages/maps/maps.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { TablesComponent } from '../../pages/tables/tables.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ToastrModule } from 'ngx-toastr';
import { ActivationCodeComponent } from '../../pages/activation-code/activation-code.component';
import {
  ActivationCodeService
} from '../../pages/activation-code/activation-code.service';
import { HttpInterceptorService } from 'src/app/shared/auth/http-interceptor.service';

import { ActivationCodeListComponent } from '../../pages/activation-code-list/activation-code-list.component';
import { ActivationCodeListService } from 'src/app/pages/activation-code-list/activation-code-list.service';
import { PosteCodeListComponent } from 'src/app/pages/poste-code-list/poste-code-list.component';
import { PosteCodeListService } from 'src/app/pages/poste-code-list/poste-code-list.service';
import { PosteCodeCreateComponent } from 'src/app/pages/poste-code-create/poste-code-create.component';
import { PosteCodeCreateService } from 'src/app/pages/poste-code-create/poste-code-create.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { UserListComponent } from 'src/app/pages/user-list/user-list.component';
import { UserListService } from 'src/app/pages/user-list/user-list.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    NgxDatatableModule
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    TablesComponent,
    IconsComponent,
    MapsComponent,
    ActivationCodeComponent,
    ActivationCodeListComponent,
    PosteCodeListComponent,
    PosteCodeCreateComponent,
    UserListComponent
  ], 
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService ,
      multi: true,
    },
    ActivationCodeService,
    ActivationCodeListService,
    PosteCodeListService,
    PosteCodeCreateService,
    UserListService
  ]
})

export class AdminLayoutModule {}
