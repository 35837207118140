import { Component, OnInit } from '@angular/core';
import { ActivationCodeListService } from './activation-code-list.service';

@Component({
  selector: 'app-activation-code-list',
  templateUrl: './activation-code-list.component.html',
  styleUrls: ['./activation-code-list.component.css']
})
export class ActivationCodeListComponent implements OnInit {
  codes: any = [];
  loading = false;
  constructor(private activationCodeListService: ActivationCodeListService ) { }

  ngOnInit(): void {
    this.getActivationCodeList();
  }

  getActivationCodeList() {
    this.loading = true;
    this.activationCodeListService.getActivationCodeList().subscribe(
      (res) => {
        console.log(res);
        this.codes = res.codes;
        this.loading =false;
      }, (err) => {
        console.log(err);
        this.loading = false;
      }
    )
  }

  getState(code) {
    const endedAt = new Date(code.endedAt);
    const now = (new Date());
    return endedAt.getTime() >= now.getTime();
  }

  seeRootPwd(code) {
    const pwd = prompt("Entrez votre mot de passe");
    if (pwd) {
      this.loading = true;
      this.activationCodeListService.getRootPwd({
        password: pwd,
        code_id: code.id
      }).subscribe(
        (res) => {
          console.log(res);
          this.loading = false;
          if (res.message === 'password_error') {
            alert('Mot de passe incorrect');
          } else if (res.message === 'success') {
            alert("Le mot de passe est " + res.password);
          }
        }, (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    } else {

    }
  }

  onCodeRemove(row) {
    if (confirm("Etes vous sûre de supprimer ?")) {
      if (!(row.startedAt || row.endedAt)) {
        console.log("DELETING");
        this.activationCodeListService.deleteActivationCode({
          id: row.id
        }).subscribe(
          (res) => {
            this.getActivationCodeList();
          }
        );
      } else {
        alert("Ce code ne peut plus être supprimer.");
      }
    }
  }

}
