import { Routes } from '@angular/router';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { IconsComponent } from '../../pages/icons/icons.component';
import { MapsComponent } from '../../pages/maps/maps.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { TablesComponent } from '../../pages/tables/tables.component';
import { ActivationCodeComponent } from '../../pages/activation-code/activation-code.component';
import { ActivationCodeListComponent } from '../../pages/activation-code-list/activation-code-list.component';
import { PosteCodeListComponent } from 'src/app/pages/poste-code-list/poste-code-list.component';
import { PosteCodeCreateComponent } from 'src/app/pages/poste-code-create/poste-code-create.component';
import { UserListComponent } from 'src/app/pages/user-list/user-list.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard',      component: DashboardComponent },
    // { path: 'user-profile',   component: UserProfileComponent },
    // { path: 'tables',         component: TablesComponent },
    // { path: 'icons',          component: IconsComponent },
    // { path: 'maps',           component: MapsComponent },
    { path: 'activation-code-create', component: ActivationCodeComponent },
    {path: 'activation-code-list', component: ActivationCodeListComponent },
    {path: 'poste-code-list', component: PosteCodeListComponent },
    {path: 'poste-code-create', component: PosteCodeCreateComponent },
    {path: 'user-list', component: UserListComponent }
];
