import { Component, OnInit } from '@angular/core';
import { UserListService } from './user-list.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  users: any = [];
  loading = false;

  constructor(private userListService: UserListService ) { }

  ngOnInit(): void {
    this.getUserList();
  }

  getUserList() {
    this.loading = true;
    this.userListService.getUserList().subscribe(
      (res) => {
        this.users = res.users;
        this.loading =false;
      }, (err) => {
        this.loading = false;
      }
    )
  }

}
