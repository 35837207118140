import { Component, OnInit } from '@angular/core';
import { PosteCodeListService } from './poste-code-list.service';

@Component({
  selector: 'app-poste-code-list',
  templateUrl: './poste-code-list.component.html',
  styleUrls: ['./poste-code-list.component.css']
})
export class PosteCodeListComponent implements OnInit {
  codes: any = [];
  loading = false;
  constructor(private posteCodeListService: PosteCodeListService ) { }

  ngOnInit(): void {
    this.getPosteCodeList();
    
  }

  getPosteCodeList() {
    this.loading = true;
    this.posteCodeListService.getPosteCodeList().subscribe(
      (res) => {
        this.codes = res.codes;
        this.loading =false;
      }, (err) => {
        this.loading = false;
      }
    )
  }

  onCodeRemove(row) {
    if (confirm("Etes vous sûre de supprimer ?")) {
      if (!(row.validatedAt)) {
        this.posteCodeListService.deletePosteCode({
          id: row.id
        }).subscribe(
          (res) => {
            this.getPosteCodeList();
          }
        );
      } else {
        alert("Ce code ne peut plus être supprimer.");
      }
    }
  }

}
