import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivationCodeService } from './activation-code.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-activation-code',
  templateUrl: './activation-code.component.html',
  styleUrls: ['./activation-code.component.css']
})
export class ActivationCodeComponent implements OnInit {
  daysOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11, 
    12
  ];
  form: FormGroup;
  error: any = null;
  spinnerLoading = false;
  constructor(private fb: FormBuilder, private toastrService: ToastrService,
    private router: Router,
    private activationCodeService: ActivationCodeService  ) {
    this.form = this.fb.group({
      duration: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });
    this.toastrService.success('En', 'FRRR');
   }

  ngOnInit(): void {
    
  }

  submit() {
    this.form.disable();
    this.spinnerLoading = true;
    this.activationCodeService.createActivationCode(this.form.value).subscribe(
      (res) => {
        this.spinnerLoading = false;
        if (res.message === 'success') {
          this.toastrService.success('Succès', "Le code d'activation a été créé avec succès.");
          this.router.navigate(['/activation-code-list']);
        } else if (res.message === 'password_error') {
          this.toastrService.error("Succès", "Mot de passe ADMIN incorrect");
        }
      }, (err) => {
        this.spinnerLoading = false;
        console.log(err);
        this.form.enable();
      } 
    )
  }

}
