import { Injectable } from '@angular/core';
// import {User} from './model/user.model';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { throwError } from 'rxjs';
import {Observable} from 'rxjs/index';
import { map, catchError } from 'rxjs/operators';
import {GlobalVariable} from '../../global';
import {Subject} from 'rxjs/index';

@Injectable()
export class ActivationCodeService {

  public logOutSubject: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  createActivationCode(body) {
    return this.http.post(GlobalVariable.BASE_API_URL+'/createAtivationCode.php', body)
    .pipe(
      map(
        (response: any) => { return response; }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          console.log(error);
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      )
    )  
  }

  showUser() {
    return this.http.get(GlobalVariable.BASE_API_URL+'/logged/user/show')
    .pipe(
      map(
        (response: any) => { return response; }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          console.log(error);
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      )
    )  
  }

}
