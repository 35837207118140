import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PosteCodeCreateService } from './poste-code-create.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-poste-code-create',
  templateUrl: './poste-code-create.component.html',
  styleUrls: ['./poste-code-create.component.css']
})
export class PosteCodeCreateComponent implements OnInit {
  nbrePostes = [
    4, 8, 12, 16
  ];
  form: FormGroup;
  error: any = null;
  spinnerLoading = false;
  constructor(private fb: FormBuilder, 
    private toastrService: ToastrService,
    private router: Router,
    private posteCodeCreateService: PosteCodeCreateService ) {
    this.form = this.fb.group({
      nbre: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });
   }

  ngOnInit(): void {
  }

  submit() {
    this.form.disable();
    this.spinnerLoading = true;
    this.posteCodeCreateService.createPosteCode(this.form.value).subscribe(
      (res) => {
        this.spinnerLoading = false;
        if (res.message === 'success') {
          this.toastrService.success('Succès', "Le code poste a été créé avec succès.");
           this.router.navigate(['/poste-code-list']);
        } else if (res.message === 'password_error') {
          this.toastrService.error("Succès", "Mot de passe ADMIN incorrect");
        }
        this.form.enable();
      }, (err) => {
        this.spinnerLoading = false;
        console.log(err);
        this.form.enable();
      } 
    )
  }

}
